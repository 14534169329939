import React from "react";

import "./NotFound.css";

export default class NotFound extends React.Component {
  render() {
    return (
      <div className="App">
        <div className="NotFoundContainer">404 Page Not Found.</div>
      </div>
    );
  }
}
